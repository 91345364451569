.bottomSheet-infoBox > div[data-rsbs-overlay='true'] {
  max-width: 500px;
  margin: auto;
}

.bottomSheet-infoBox {
  width: 100%;

}

div[role=dialog]{
  z-index: 999;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
}

.MuiSnackbarContent-root {
  z-index: 99999;

}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root{
  z-index: 99999;
}